
.head{
    margin: 1rem 0rem -3rem 1rem
}

.img{
    margin: 2rem 0rem 0rem 0rem;
    width: 400px;
    height: auto;
}

.left{
  width: 40%;
  text-align: center;
display: inline-block;
}

.right{
    font-size: x-large;
   vertical-align: middle;
    padding: 1rem;
    width: 50%;
display: inline-block;
margin: 0rem -10vw 0rem 0rem;
}

@media screen and (max-width: 1000px) {

    .left{ width: 100%;}
    .right{width: 100%;margin: -10vw 0rem 0rem 0rem;font-size: large;}
}


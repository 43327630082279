.num {
    font-size: 7vw;
    font-weight: bolder;
}

.name {
    font-size: 3vw;
    font-weight: bolder;
    margin: -2vw 0rem 0rem 0rem;
}

.dis {
    font-size: 1.5vw;
}

.wi {

    min-width: 20vw;

    margin: 10px;
    color: white;
    display: block;
    margin: 1vw 4vw 1vw 2vw;
    width: 20vw;
}

.icons-cards {
    display: flex;
    flex-direction: row;
    column-gap: 1.5vw;
    margin-top: 0.5vw
}


@media screen and (max-width: 1000px) {
    .wi {
        min-width: 30vw;
    }

    .dis {
        font-size: 2vw;
    }

    .name {
        font-size: 5vw;
    }

    .num {
        font-size: 7.5vw;
    }

    .dis {
        font-size: 2.5vw;
        font-style: normal;
    }
}
.parent{
    display: flex;
flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
}

.cir{
    position: absolute;
    height: 50vw;
    width: 50vw;
    background-color: white;
    border-radius: 50%;
margin: -30%  0rem  0rem -30%;
}
.top{

    color: #242B30;
  
    position: relative;
    font-size: 12vw;
    font-weight: bolder;
    margin: 0rem 0rem -1vw 0rem;
    z-index: 0;

   
    background: -webkit-linear-gradient(90deg, rgba(36,43,48,1) 40%, rgba(255,255,255,1) 80%);
    background-clip: text;
    -webkit-text-stroke: 7px transparent;
    
 
}
.middle{
    position: absolute;
    font-size: 12vw;
    font-weight: bolder;
    margin: -7vw 0rem 0rem 0rem;
    z-index: 1;

}
.bottom{
    color: #242B30;
  
    position: relative;
    font-size: 12vw;
    font-weight: bolder;
    margin: -1vw 0rem 0rem 0rem;
    z-index: 0;

   
    background: -webkit-linear-gradient(-90deg, rgba(36,43,48,1) 40%, rgba(255,255,255,1) 80%);
    background-clip: text;
    -webkit-text-stroke: 7px transparent;

}

.te{
   font-size: xx-large;
    display: flex;
    justify-self: center;
    justify-content: center;
    text-align: center;
}

.dheeraj{
    display: flex;
    justify-content: center;
    font-size: 4vw;
}
@media screen and (max-width: 1000px) {
    .te{font-size: 3vw;}
    .dheeraj{font-size: 10vw;} 
    .top{font-size: 17vw;}
    .middle{font-size: 17vw; margin-top: -10vw;}
    .bottom{font-size: 17vw;}
    .cir{height:100vw; width: 100vw;margin: -50% 0rem 0rem 0rem;display: flex;text-align: center;}
}



.caro{
    overflow-y: hidden;
    height: 28.2vw;
    scroll-behavior: smooth;
}

.arrow{
  
    border-radius: 50%;
    padding: 1px 2px 1px 2px;
    width: fit-content;
    background-color: #7d7d7d;
}

.arrowOut{
    width: 100%;
    text-align: center;
    background-color: transparent;
    border: none;
}

@media screen and (max-width: 1000px) {
    .caro{height: 70vw;}
}
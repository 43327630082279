.he{
    margin: 0vw 0vw 0vw 5vw;
    font-size: 6vw;
}

.main{
    margin: 4vw 0vw 9vw 0rem;
    text-align: center;

    
}
.container{
   
    width: 30vw;
    padding: 1rem;
    display: inline-block;
    vertical-align: top;
}

.container2{
    font-size: 1.5vw;
    top:auto;
    text-align: left;

}

.sideheading{
    text-align: left;
    margin: 0rem 0rem 1rem 0rem;
    font-size: 3vw;
}

.skills{
    margin: 0rem 0.5rem 0.5rem 0rem;
    display: inline-block;
    width: fit-content;
    padding: 0.5rem;
    border: 1px solid white;
    border-radius: 1.5rem;
    font-weight: lighter;
}

@media screen and (max-width: 1000px) {
    .he{font-size: xx-large;}
    .container{width: 90%;margin: 1rem 0rem 0rem 3rem;}
    .main{text-align: left;}
    .container2{font-size: 4vw;}
    .skills{font-size: 3.7vw;}
    .sideheading{font-size: 5vw;}
}
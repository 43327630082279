.certificate{
    display: inline-block;
    margin: 0rem 5vw 0rem 5vw;
    height: 23vw;
    width: 35vw;
}

.inside{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 3vw 0rem 5vw 0rem;
   
}

.t{
    font-size: 5rem;
    height: auto;
    width: 50vw;
    display: inline-block;
    align-content: center ;

}

.info{
    font-size: x-large;
}

@media screen and (max-width:1000px) {
    .info{
        margin: 0rem 0rem 0rem 3vw;
        font-size:3vw;
    } 
    .certificate{height: 30vw;}
}


